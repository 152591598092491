import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Element } from 'react-scroll';
import { logEvent } from '../../utils/amplitude';
import appStoreImage from '../../images/people-connect-total-health/app-store.svg';
import googlePlayImage from '../../images/people-connect-total-health/google-play.svg';
import MBCToolkitVideoShelf from './MBCToolkitVideoShelf';

const ProviderSection = props => {
  const handleOnClick = () => {
    logEvent('benefits redirect', { page: props.provider.name });
  };
  const logoSection = (
    <Element name={props.provider.path}>
      <div
        className="bci-white-box"
        style={{ background: props.provider.logoBorderColor }}
      >
        <img
          style={{ maxWidth: props.provider.logoClass && '250px' }}
          src={
            props.french && props.selectedLang == 'fr' && props.provider.frLogo
              ? props.provider.frLogo
              : props.provider.logo
          }
          alt={`${props.provider.name}-logo`}
        />
        {(props.provider.frSecondLogo || props.provider.secondLogo) && (
          <img
            style={{ marginTop: '1.7rem' }}
            src={
              props.french &&
              props.selectedLang == 'fr' &&
              props.provider.frSecondLogo
                ? props.provider.frSecondLogo
                : props.provider.secondLogo
            }
            alt={`${props.provider.name}-second-logo`}
          />
        )}
      </div>
    </Element>
  );

  const noBorderLogoSection = (
    <Element name={props.provider.path}>
      <div
        className={props.isMedavie ? 'medavie-hidden-box' : 'bci-hidden-box'}
      >
        <img
          style={{ maxWidth: props.provider.logoClass && '250px' }}
          src={
            props.french && props.selectedLang == 'fr' && props.provider.frLogo
              ? props.provider.frLogo
              : props.provider.logo
          }
          alt={`${props.provider.name}-logo`}
        />
        {(props.provider.frSecondLogo || props.provider.secondLogo) && (
          <img
            style={{ marginTop: '1.7rem' }}
            src={
              props.french &&
              props.selectedLang == 'fr' &&
              props.provider.frSecondLogo
                ? props.provider.frSecondLogo
                : props.provider.secondLogo
            }
            alt={`${props.provider.name}-second-logo`}
          />
        )}
      </div>
    </Element>
  );

  const descSection = (
    <>
      {props.provider.type && (
        <h2 style={{ color: `${props.provider.typeColor}` }}>
          <FormattedHTMLMessage
            id={`corporateNavPage.${props.provider.name}.type`}
            defaultMessage={
              props.french && props.selectedLang == 'fr'
                ? props.provider.frType
                : props.provider.type
            }
          />
        </h2>
      )}
      <h1 style={{ color: `${props.provider.titleColor}` }}>
        <FormattedHTMLMessage
          id={`corporateNavPage.${props.provider.name}.name`}
          defaultMessage={
            props.french && props.selectedLang == 'fr'
              ? props.provider.frName
              : props.provider.name
          }
        />
      </h1>
      <p style={{ color: `${props.provider.descriptionColor}` }}>
        <FormattedHTMLMessage
          id={`corporateNavPage.${props.provider.name}.primaryDescription`}
          defaultMessage={
            props.french && props.selectedLang == 'fr'
              ? props.provider.frPrimaryDescription
              : props.provider.primaryDescription
          }
        />
      </p>
      <div
        className="divider"
        style={{ border: `1px solid ${props.provider.dividerColor}` }}
      />
      <p style={{ color: `${props.provider.descriptionColor}` }}>
        {(props.provider.secondaryDescription ||
          props.provider.frSecondaryDescription) && (
          <FormattedHTMLMessage
            id={`corporateNavPage.${props.provider.name}.secondaryDescription`}
            defaultMessage={
              props.french && props.selectedLang == 'fr'
                ? props.provider.frSecondaryDescription
                : props.provider.secondaryDescription
            }
          />
        )}
      </p>
      {(props.provider.googlePlay || props.provider.appStore) && (
        <div className="left">
          {props.provider.appleStore && (
            <a href={props.provider.appleUrl} target="_blank" rel="noreferrer">
              <img src={appStoreImage} alt="App Store" />
            </a>
          )}
          &nbsp;&nbsp;
          {props.provider.googlePlay && (
            <a href={props.provider.playUrl} target="_blank" rel="noreferrer">
              <img src={googlePlayImage} alt="Google Play" />
            </a>
          )}
        </div>
      )}
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {(props.provider.buttonLabel || props.provider.frButtonLabel) && (
          <div
            className="button-link"
            style={{ borderColor: props.provider.ctaColor }}
          >
            <a
              href={
                props.french && props.selectedLang == 'fr'
                  ? props.provider.frButtonUrl
                  : props.provider.buttonUrl
              }
              onClick={handleOnClick}
              target="_blank"
              style={{ color: props.provider.ctaTextColor }}
            >
              {props.french && props.selectedLang == 'fr'
                ? props.provider.frButtonLabel
                : props.provider.buttonLabel}
            </a>
          </div>
        )}
        {(props.provider.frSecondButtonLabel ||
          props.provider.secondButtonLabel) && (
          <div className="button-link second-button">
            <a
              href={
                props.french && props.selectedLang == 'fr'
                  ? props.provider.secondFrButtonUrl
                  : props.provider.secondButtonUrl
              }
              onClick={handleOnClick}
              target="_blank"
            >
              {props.french && props.selectedLang == 'fr'
                ? props.provider.secondFrButtonLabel
                : props.provider.secondButtonLabel}
            </a>
          </div>
        )}
      </div>
      {(props.provider.buttonDescription ||
        props.provider.buttonFrDescription) && (
        <div style={{ marginTop: '60px' }}>
          <FormattedHTMLMessage
            tagName="p"
            id={`corporateNavPage.${props.provider.name}.buttonDescription`}
            defaultMessage={
              props.french && props.selectedLang == 'fr'
                ? props.provider.buttonFrDescription
                : props.provider.buttonDescription
            }
          />
        </div>
      )}
    </>
  );
  return (
    <div>
      <div
        className={
          props.isMedavie
            ? 'section-medavie-custom'
            : `section-${props.sectionNo}`
        }
        style={{ backgroundColor: `${props.provider.customBackground}` }}
      >
        {props.sectionNo % 2 === 0 ? (
          <div className="container">
            <div className="left">
              {props.provider.noBorder ? noBorderLogoSection : logoSection}
            </div>
            <div className="right">{descSection}</div>
          </div>
        ) : (
          <div className="container">
            <div className="left">{descSection}</div>
            <div className="right">
              {props.provider.noBorder ? noBorderLogoSection : logoSection}
            </div>
          </div>
        )}
      </div>
      {props.provider.path === 'webinarsAndTraining' && props.isMedavie && (
        <MBCToolkitVideoShelf />
      )}
    </div>
  );
};

export default ProviderSection;
