import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { getLocale } from '../../utils/locale';

const MBCToolkitVideoShelf = () => {
  const [isOpen, setIsOpen] = useState(false);
  const locale = getLocale();

  return (
    <div className="shelf-container">
      <h1>
        <FormattedMessage
          defaultMessage="Watch recording of a previous webinar."
          id="eap.watchARecording"
        />
      </h1>
      <button
        type="button"
        className="button-link"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          <FormattedMessage
            id="eap.hideSessions"
            defaultMessage="HIDE WEBINARS"
          />
        ) : (
          <FormattedMessage
            id="eap.showSessions"
            defaultMessage="SHOW WEBINARS"
          />
        )}
      </button>
      {isOpen && (
        <div className="shelf">
          <div className="shelf-item">
            <a
              href={
                locale === 'en'
                  ? 'https://vimeo.com/892716988/39983f3c39?share=copy'
                  : 'https://vimeo.com/892697791/b76476d312?share=copy'
              }
            >
              <h2>
                <FormattedMessage
                  defaultMessage="Managing Holiday Stress"
                  id="eap.managingHolidayStress"
                />
              </h2>
            </a>
          </div>
          <div className="shelf-item">
            <a
              href={
                locale === 'en'
                  ? 'https://inkblottherapy.zoom.us/rec/play/6Uv9KZ_Gg6UlXLOZfm3rQ9BFsBZGmvOUmzKkL6OLLlZDcJk8waS7Vsp6V7aFoU5BCnjcRRD_8nLrCf5m.ckuz3iNryUTo9ZfQ?canPlayFromShare=true&from=share_recording_detail&startTime=1716472834000&componentName=rec-play&originRequestUrl=https%3A%2F%2Finkblottherapy.zoom.us%2Frec%2Fshare%2FBL02U0yxhH7kd1ys052O0wKdUGWY0eeCuuiozDCfvoG_H_gHs6Ibc9PQnxPJ3i7Y.L0kFVxt43goDz4ma%3FstartTime%3D1716472834000'
                  : 'https://inkblottherapy.zoom.us/rec/play/q189VLXMRhQH3pkR488zibP1MKYtp3OFx9YU9SjN48kjrtvNgan-_a2FwvMjL02Lmn6EjzfTZY95mZlb.jmRktxcax8Q5JQNH?canPlayFromShare=true&from=my_recording&startTime=1716487192000&componentName=rec-play&originRequestUrl=https%3A%2F%2Finkblottherapy.zoom.us%2Frec%2Fshare%2FM3OlFq_niiSL9EnXkSNGx1psdD_gu3y1YRCdjU7eyNRipArs6_wh6aZNMO4DTlW5.lhLlSGMt55tuXaFF%3FstartTime%3D1716487192000'
              }
            >
              <h2>
                <FormattedMessage
                  defaultMessage="Critical incident management services"
                  id="eap.criticalIncidentManagement"
                />
              </h2>
            </a>
          </div>
          <div className="shelf-item">
            <a
              href={
                locale === 'en'
                  ? 'https://inkblottherapy.zoom.us/rec/play/vzzdGBGHfT9BA9rvh81WfxeMFzEERF3DoDsc_wcPYkNgLu42eKT5nBwIWTTRc3hxdDugWbdQHMYEd-Lw.ErMglQcX2s-VvTEV?canPlayFromShare=true&from=share_recording_detail&continueMode=true&componentName=rec-play&originRequestUrl=https%3A%2F%2Finkblottherapy.zoom.us%2Frec%2Fshare%2F6pIo8KrHoBYd0RovhPSeae_xOWhe0Y5ZuXBzrUmAh0OtlEn33m-y3vJSBS_0mvmW.jM48dXHONsBFCjrb'
                  : 'https://inkblottherapy.zoom.us/rec/play/OF52w_FRa3iHJdXM5xTqdZT5jxTlp_kaI7pLZ1QxR8DYMFgktTVw2FKjmVcXUw5WHEK3BzYkyS0rHawl.RvOvmyDOo2r0EhIE?canPlayFromShare=true&from=share_recording_detail&continueMode=true&componentName=rec-play&originRequestUrl=https%3A%2F%2Finkblottherapy.zoom.us%2Frec%2Fshare%2F9m4r0YmpHb_wdehYdZdB9ZXpzVHVz2E_wopTxXCLTLIUJpytsKliDK9jCfnkyfoG._dtnyh-kvsn0JTfU'
              }
            >
              <h2>
                <FormattedMessage
                  defaultMessage="Work, Health and Life Services Webinar"
                  id="eap.workHealthLifeServices"
                />
              </h2>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default MBCToolkitVideoShelf;
