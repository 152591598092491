import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';
import _ from 'lodash';

import SessionTwo from '../../images/custom/illustrations/session-2.svg';
import InkblotLogo from '../../images/nav/inkblot-logo.svg';
import { SERVER_URL } from '../../utils/environment';
import {
  parseCompanyCodeFromPath,
  unauthAuditProgress,
  whichCompany,
} from '../../utils/pageUtils';
import LogEventOnMount from '../LogEventOnMount';
import { OPEN_SIGNIN } from '../../utils/amplitude';
import { redirectToTherapy } from '../../utils/redirect';
import SpinnerButton from '../SpinnerButton';
import MigrationPopup from '../MigrationPopup';
import FppmPopUp from '../MigrationPopup/FppmPopUp';

import EyeIcon from '../EyeIcon';
import { getEmployeeTittleEN, getEmployeeTittleFR } from './EmployeeIdSignup';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      visible: false,
      migrationPopup: false,
    };

    this.companyCode = parseCompanyCodeFromPath();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.clearError, false);
    unauthAuditProgress(this.companyCode, 'open_signin');
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.clearError, false);
  }

  clearError = () => {
    this.setState({ error: null });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.signIn();
  };

  getErrorMessage = error => {
    switch (whichCompany().path) {
      case 'arta':
        return 'The member id or password you provided is incorrect.';
      case 'eiqc':
        return 'The student id or password you provided is incorrect.';
      default:
        return error;
    }
  };

  getEmployeeType = () => {
    const companyPath = whichCompany().path;
    switch (companyPath) {
      case 'arta':
        return { en: 'Member', fr: 'membres' };
      case 'eiqc':
        return { en: 'Student', fr: 'étudiant' };
      case 'asebp':
        return { en: 'ASEBP', fr: 'ASEBP' };
      default:
        return { en: 'Employee', fr: 'employé(e)' };
    }
  };

  // copied from src/components/auth/SignIn.js
  signIn = () => {
    this.setState({ loading: true, error: null });
    const values = _.omit(this.state, ['error']);
    values.company = new URL(window.location.href).searchParams.get('company');
    axios
      .post(`${SERVER_URL}/api/v2/forgot_passwords/atb_login`, {
        ...values,
        source: 'therapy',
      })
      .then(mainResponse => {
        // success in DB
        const {
          gsplus_migration: gsPlusMigration,
          company_name: companyName,
          access_token: token,
        } = mainResponse.data;

        if (
          companyName ===
            'Fraternité des policiers et policières de Montréal (FPPM)' ||
          companyName ===
            'Fraternité des policiers et policières de Montréal (pour employés)'
        ) {
          this.setState({ fppmMigrationPopup: true, loading: false });
          return;
        }
        if (gsPlusMigration) {
          this.setState({ migrationPopup: true, loading: false });
          return;
        }

        redirectToTherapy(token);
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
        this.setState({
          error: 'The employee id or password you provided is incorrect.',
        });
      });
  };

  renderBottom = () => (
    <div>
      {!whichCompany().hideSignUp && (
        <h4>
          {this.props.intl.formatMessage({
            id: 'eap.dontHave',
            defaultMessage: "Don't have an account? ",
          })}{' '}
          <Link
            to={`/sign_up?company=${this.companyCode}`}
            style={{ color: '#2a5ccd' }}
          >
            {this.props.intl.formatMessage({
              id: 'eap.signUp',
              defaultMessage: 'Sign Up',
            })}
          </Link>
        </h4>
      )}
      <h4>
        <Link to="/forgot_password" style={{ color: '#2a5ccd' }}>
          {this.props.intl.formatMessage({
            id: 'auth.forgotMyPassword',
            defaultMessage: 'Forgot My Password',
          })}
        </Link>
      </h4>
    </div>
  );

  render() {
    const {
      error,
      loading,
      visible,
      migrationPopup,
      fppmMigrationPopup,
    } = this.state;
    const language = localStorage.getItem('selectedLanguage');
    return (
      <div className="custom-auth" role="main">
        <div className="auth-container custom-auth-container">
          <div className="custom-auth-text">
            <h2>
              {this.props.intl.formatMessage({
                id: 'eap.signin',
                defaultMessage: 'Sign In',
              })}
            </h2>
            <form onSubmit={this.handleSubmit}>
              <div className="sign-in-alternate">
                <h4>
                  {this.props.intl.formatMessage(
                    {
                      id: 'eap.employeeIdMemberOrEmployee',
                      defaultMessage: 'Employee ID',
                    },
                    {
                      employeeEN: getEmployeeTittleEN(whichCompany().path),
                      employeeFR: getEmployeeTittleFR(whichCompany().path),
                    },
                  )}
                </h4>
                <Link
                  to={`/sign_in${
                    whichCompany().hideSignUp ? '?vis=false' : ''
                  }`}
                  style={{
                    color: '#2a5ccd',
                    fontFamily: 'Barlow',
                    fontWeight: '600',
                  }}
                >
                  {this.props.intl.formatMessage({
                    id: 'eap.signInAlternate',
                    defaultMessage: 'Sign in by email',
                  })}
                </Link>
              </div>
              <input
                onChange={value => this.handleChange(value)}
                type="text"
                name="employee_id"
                placeholder={this.props.intl.formatMessage(
                  {
                    id: 'eap.employeeIdPlaceHolder',
                    defaultMessage: 'Your Employee ID',
                  },
                  {
                    employeeEN: this.getEmployeeType().en,
                    employeeFR: this.getEmployeeType().fr,
                  },
                )}
              />
              <h4>
                {this.props.intl.formatMessage({
                  id: 'eap.password',
                  defaultMessage: 'Password',
                })}
              </h4>
              <div className="password-input">
                <div className="text-input">
                  <input
                    onChange={value => this.handleChange(value)}
                    type={visible ? 'text' : 'password'}
                    name="password"
                    placeholder={
                      language === 'fr' ? 'Votre mot de passe' : 'Your Password'
                    }
                  />
                </div>
                <EyeIcon
                  isVisible={visible}
                  onClick={() => this.setState({ visible: !visible })}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 240,
                  marginTop: 40,
                }}
              >
                <SpinnerButton
                  label={this.props.intl.formatMessage({
                    id: 'eap.signin',
                    defaultMessage: 'Sign In',
                  })}
                  type="submit"
                  loading={loading}
                />
              </div>
            </form>
            {error && (
              <p
                className="error-text"
                aria-live="polite"
                role="alert"
                style={{ width: 288, color: 'red' }}
              >
                {this.getErrorMessage(error)}
              </p>
            )}
            {this.renderBottom()}
          </div>
          <img src={SessionTwo} alt="" />
          <div className="custom-auth-logo">
            <img src={InkblotLogo} alt="inkblot logo" />
          </div>
        </div>
        <MigrationPopup
          open={migrationPopup}
          onClose={() => this.setState({ migrationPopup: false })}
        />
        <FppmPopUp
          open={fppmMigrationPopup}
          onClose={() => this.setState({ fppmMigrationPopup: false })}
        />
        <LogEventOnMount
          eventType={OPEN_SIGNIN}
          eventProperties={{ page: this.companyCode }}
        />
      </div>
    );
  }
}

SignIn.propTypes = {
  intl: PropTypes.object,
};

SignIn.defaultProps = {
  intl: {},
};

export default injectIntl(SignIn);
